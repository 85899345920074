<template>
  <v-locale-provider>
    <router-view />
  </v-locale-provider>
</template>

<script>
export default {
  mounted() {
    this.$store.commit("updateFromLocalStorage");
    this.$vuetify.locale.current = localStorage.getItem("lang");
  },
};
</script>
