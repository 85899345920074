export default {
  navLinks: {
    home: "Home",
    university: "Universites",
    vission: "Vission",
    blogs: "Blogs",
    services: "Services",
    about: "About",
    faq: "FAQs",
    contact: "Contact",
    account: "Account",
    register: "Sign Up",
    login: "Sign In",
    ar: "AR",
    en: "EN",
  },
  hero: {
    title:
      " Welcome to Al Masar Al Taelimi Study Abroad Services! Embarking on a journey to study abroad is a transformative experience that opens doors to new opportunities and broadens horizons. At Al Masar Al Taelimi, we specialize in guiding students through the entire process, ensuring a seamless transition to prestigious universities worldwide, including Turkey, Cyprus, Canada, the USA, Europe, and other countries.",
    subtitle:
      " At Al Masar Al Taelimi, we offer a complete range of services to ensure your study abroad journey is smooth and successful.",
    name: "Name & Surname",
    mobile: "Mobile Number",
    email: "Email Address",
    nationality: "Nationality",
    country: "Residence Country",
    city: "City",
    degree: "Study Degree",
    appoiment: "Appoiment",
    program: "Program Name",
    warning:
      "Please be aware that we do not offer free scholarships. By completing this form, you consent to us storing and using your information for communication purposes. Rest assured, your data will not be shared with any third parties.",
    submit: "Submit",
    contact: "Contact Us",
    fill: "Fill out the form below so we can contact you",
    type: "Attendance type",
    type1: "Online",
    type2: "Present",
    study1: "Bachelor",
    study2: "Master",
    study3: "Doctorate",
    study4: "Associate",
    availableTime: "No Available Time Today",
    copyRight: "© 2024 Copyright:",
  },
  universities: {
    title: "BEST UNIVERSITIES",
    apply: "Apply Now",
  },
  vission: {
    title: "Vission & Mission",
    vission: "Vission",
    vission_title:
      "To be the leading innovator in education and career guidance, where every student’s potential is fully realized through personalized, ai-enhanced pathways to academic and professional success.",
    mission: "Mission",
    mission_title:
      "To revolutionize the journey to higher education and career fulfillment by seamlessly integrating cutting-edge technology with expert guidance, ensuring that each student receives personalized, actionable insights that align with their unique strengths and aspirations.",
    personalization: "Personalization",
    p_title:
      "Recognize and cater to the unique interests, capabilities, and aspirations of each student, ensuring tailored recommendations and guidance.",
    empathy: "Empathy",
    e_title:
      "Blend the precision of AI with the empathy and insight of experienced human consultants, ensuring students feel understood, valued, and supported.",
    excellence: "Excellence",
    ex_title:
      "Strive for excellence in all aspects of our services, from university recommendations to career counseling and application assistance.",
    empowerment: "Empowerment",
    em_title:
      "Equip students with the knowledge and tools they need to make informed decisions about their education and careers, fostering confidence and independence.",
    integrity: "Integrity",
    i_title:
      "Uphold the highest standards of honesty and transparency in our interactions, ensuring trust and reliability in our services.",
    collaboration: "Collaboration",
    c_title:
      "Foster a collaborative environment where technology and human expertise work hand-in-hand to achieve the best outcomes for our students.",
    commitment: "Commitment",
    co_title:
      "Dedicate ourselves to the continuous improvement of our services and the success of our students, always aiming to exceed expectations.",
    footer:
      "By adhering to these values, Al Masar Al Taelimi commits to guiding students through their educational journeys with innovative solutions and a compassionate approach, paving the way for their future success.",
  },
  blogs: {
    title: "Blogs",
    button: "Read More",
  },
  services: {
    title: "OUR COMPREHENSIVE SERVICES",
    s1_title: "Personalized Consultation",
    s1: "Individualized Guidance: We provide personalized guidance to help you choose the right university and program that align with your academic goals and personal preferences. Our expert consultants take into account your unique strengths, aspirations, and interests.",
    s2_title: "Application Assistance",
    s2: "End-to-End Support: Our team assists with the entire application process, ensuring all documents are prepared accurately and submitted on time. We help you craft compelling personal statements and prepare for interviews, enhancing your chances of admission.",
    s3_title: "Visa Support",
    s3: "Comprehensive Assistance: Navigating visa requirements can be daunting. We offer thorough support to help you understand and complete the visa application process successfully, ensuring you meet all necessary requirements.",
    s4_title: "Travel and Accommodation Arrangements",
    s4: "Detailed Planning: We assist in planning your travel and securing safe, comfortable accommodation, whether on-campus or off-campus. Our goal is to ensure you have a smooth transition to your new environment.",
    s5_title: "Pre-Departure Orientation",
    s5: "Comprehensive Preparation: We prepare you for your new adventure with orientation sessions covering important aspects of living and studying abroad. This includes cultural insights, academic expectations, and practical tips to help you adapt quickly.",
    s6_title: "Ongoing Support",
    s6: "Continued Assistance: Our support doesn’t end once you reach your destination. We continue to provide assistance to help you integrate smoothly and succeed academically, ensuring you have the resources you need throughout your study abroad experience.",
    why: "Why you should",
    apply: "Apply?",
    titlepart: "with us?",
    subtitle:
      "At Al Masar Al Taelimi, we are dedicated to transforming your educational and career aspirations into reality, especially if you aim to study abroad. Here's why choosing us will be the best decision for your future",
    w1_title: "Personalized Guidance",
    w1: "Tailored Recommendations: We understand that every student is unique. Our personalized university recommendations are designed to match your interests, capabilities, and career aspirations, ensuring a perfect fit for your academic and personal growth, both locally and internationally.",
    w2_title: "Cutting-Edge Technology",
    w2: "AI-Driven Insights: Utilizing the latest in artificial intelligence, we provide precise, data-driven insights to enhance your application materials, making you stand out in the competitive landscape of international admissions.",
    w3_title: "Expert Consultants",
    w3: "Experienced Professionals: Our team of seasoned educational consultants combines deep expertise with empathy, providing you with guidance that is both knowledgeable and compassionate. Our consultants have extensive experience with the international education system and can help you navigate it effectively.",
    w4_title: "Holistic Approach",
    w4: "Integrated Solutions: We offer a comprehensive approach to university admissions and career planning by blending AI precision with the human touch. This dual approach ensures you receive well-rounded support for studying abroad, from selecting the right universities to understanding cultural differences.",
    w5_title: "Comprehensive Study Abroad Services",
    w5: "End-to-End Support: Our services cover every aspect of studying abroad. University Recommendations: We identify universities worldwide that best match your profile. Application Assistance: Our team helps you craft compelling personal statements, prepare for interviews, and manage the entire application process. Visa Guidance: We provide step-by-step assistance with visa applications to ensure a smooth transition. Pre-Departure Orientation: We offer sessions to help you understand what to expect and how to prepare for life in a new country. Accommodation Assistance: We guide you in finding suitable housing options near your chosen university.",
    w6_title: "Empowerment",
    w6: "Knowledge and Tools: We equip you with the necessary knowledge and tools to make informed decisions about your education and career, fostering a sense of confidence and independence, especially when studying in a foreign country.",
    w7_title: "Commitment to Excellence",
    w7: "Dedicated to Your Success: Our commitment to excellence means we continuously strive to improve our services and exceed your expectations, ensuring the highest standards of quality and support.",

    how: "How to",
    howtitle:
      "Ready to take the next step in your educational journey? Contact us today to learn more about studying abroad and to start your application process.",
    company: "Al Masar Al Taelimi",
    title2:
      "we are dedicated to helping you achieve your academic dreams abroad. Let us guide you to a successful and enriching international education experience.",
    applybtn: "Apply now",
  },
  about: {
    title: "About",
    name: "AL MASAR AL TAELIMI",
    text: "Welcome to E-Ibtikar, where technology meets guidance to create pathways to success. We are a forward-thinking software development company that harnesses the power of cutting-edge tools for app development and artificial intelligence for client success. Our mission, which we are deeply passionate about, is to revolutionize university admission and career counseling, making the journey to higher education seamless and personalized for every student. We believe in a future where every student's potential is fully realized.",
    text2:
      "Al Masar Al Taelimi, a brand of E-Ibtikar, is your personalized guide to university admission and career counseling. We recognize that each student has unique interests, capabilities, and aspirations. Our innovative approach combines the expertise of our experienced educational consultants with advanced AI analysis. This dual approach ensures you receive tailored recommendations that align with your strengths and ambitions, making you feel understood and valued.",
    text3:
      "Our commitment is to empower students with the knowledge and tools they need to make informed decisions about their education and careers. By blending the precision of AI with the empathy and insight of human consultants, we offer a holistic approach to university admissions and career planning.",
  },
  faq: {
    title: "FREQUENTLY",
    subtitle: "ASKED QUESTIONS",
  },
  ready: {
    title: "Ready to ",
    apply: "Apply? ",
    subtitle: "Fill the form below",
    name: "Name & Surname",
    mobile: "Mobile Number",
    email: "Email Address",
    nationality: "Select Nationality",
    country: "Residence Country",
    degree: "Study Degree",
    program: "Program Name",
    warning:
      "Please be aware that we do not offer free scholarships. By completing this form, you consent to us storing and using your information for communication purposes. Rest assured, your data will not be shared with any third parties.",
    submit: "Submit",
  },
  account: {
    register: {
      title: "Create new account",
      fname: "First Name",
      lname: "Last Name",
      email: "Email Address",
      password: "Password",
      submit: "Sign Up",
      login: "Already Have an account?",
    },
    login: {
      title: "Welcome Back",
      email: "Email Address",
      password: "Password",
      new: "New Account?",
      forget: "Forget Ppassword?",
      submit: "Sign In",
    },
  },
  applynow: {
    title: "Apply For ",
    step1: "Personal Information",
    step2: "Study Information",
    step3: "Documents Information",
    name: "Name",
    surname: "Surname",
    gender: "Gender",
    male: "Male",
    female: "Female",
    father: "Father Name",
    mother: "Mother Name",
    email: "Email Address",
    confirmemail: "Confirm Email",
    phone: "Phone Number",
    confirmphone: "Confirm Phone Number",
    dual: "I have dual citizenship",
    nationality: "Nationality",
    country: "Country",
    passport: "Passport No",
    snationality: "Secound Nationality",
    spassport: "Secound Passport No",
    university: "University",
    Major: "Major",
    lang: "Study Language",
    student: "Student Picture",
    passportpic: "Passport Picture",
    spassportpic: "Seconud Passport",
    certificate: "Certificate",
    transcript: "Transcript",
    ex1: "Extra Document 1",
    ex2: "Extra Document 2",
    ex3: "Extra Document 3",
    ex4: "Extra Document 4",
    ex5: "Extra Document 5",
    submit: "Submit",
    next: "Next",
    previous: "Previous",
  },
};
