import { createStore } from "vuex";

export default createStore({
  state: {
    TOKEN: null,
    CURRENT_LOCAL: "en",
  },
  getters: {},
  mutations: {
    updateFromLocalStorage(state) {
      const TOKEN = localStorage.getItem("_token");
      state.TOKEN = TOKEN;
    },
    logOut(state) {
      state.TOKEN = null;
      localStorage.removeItem("_token");
    },
  },

  actions: {},
  modules: {},
});
