import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/:pathMatch(.)",
    name: "NotFound",
    beforeEnter: () => {
      router.push('/')
    }
  },
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/blog/:slug",
    name: "blog.read",
    component: () => import("@/views/BlogView.vue"),
  },
  {
    path: "/apply/:id",
    name: "apply",
    component: () => import("@/views/ApplyNowView.vue"),
    // beforeEnter: () => {
    //   if (localStorage.getItem("_token") == null) {
    //     router.push("/auth/signin");
    //   }
    // },
  },
  {
    path: "/auth",

    children: [
      {
        path: "signin",
        name: "auth.signin",
        component: () => import("@/components/Auth/SignIn.vue"),
        beforeEnter: () => {
          if (localStorage.getItem("_token") != null) {
            router.push("/");
            return;
          }
        },
      },
      {
        path: "signup",
        name: "auth.signup",
        beforeEnter: () => {
          if (localStorage.getItem("_token") != null) {
            router.push("/");
            return;
          }
        },
        component: () => import("@/components/Auth/SignUp.vue"),
      },
      {
        path: "dash",
        name: "auth.admin",
        component: () => import("@/components/Auth/AdminLogin.vue"),
        beforeEnter: () => {
          if (localStorage.getItem("_admin") != null) {
            router.push("/dashboard/home");
            return;
          }
        },
      },
      {
        path: "confirmpassword/:uid/:token",
        name: "auth.forget",
        component: () => import("@/components/Auth/ChangePassword.vue"),
      },
      {
        path: "resetpassword",
        name: "auth.reset",
        component: () => import("@/components/Auth/ResetPassword.vue"),
      },
    ],
  },
  {
    path: "/dashboard",
    component: () => import("@/views/DashboardView.vue"),
    beforeEnter: () => {
      if (localStorage.getItem("_admin") == null) {
        router.push("/");
      }
    },
    children: [
      {
        path: "home",
        name: "dashboard.home",
        component: () => import("@/components/Dashboard/DashHome.vue"),
      },
      {
        path: "university",
        name: "dashboard.uni",
        component: () => import("@/components/Dashboard/universityAdd.vue"),
      },
      {
        path: "university/edit/:id",
        name: "dashboard.uni.edit",
        component: () => import("@/components/Dashboard/universityEdit.vue"),
      },
      {
        path: "university/view/apply",
        name: "dashboard.uni.apply",
        component: () => import("@/components/Dashboard/UniversityApply.vue"),
      },
      {
        path: "university/apply/view/:id",
        name: "dashboard.uni.apply.details",
        component: () =>
          import("@/components/Dashboard/UniversityApplyDetails.vue"),
      },
      {
        path: "faq/",
        name: "dashboard.faq",
        component: () => import("@/components/Dashboard/faqAdd.vue"),
      },
      {
        path: "faq/edit/:id",
        name: "dashboard.faq.edit",
        component: () => import("@/components/Dashboard/faqEdit.vue"),
      },
      {
        path: "appoiments/",
        name: "dashboard.appoiments",
        component: () => import("@/components/Dashboard/appoimentList.vue"),
      },
      {
        path: "appoiments/list",
        name: "dashboard.appoiments.list",
        component: () =>
          import("@/components/Dashboard/addAvailableAppoiment.vue"),
      },
      {
        path: "blogs",
        name: "dashboard.blogs",
        component: () => import("@/components/Dashboard/BlogsEditor.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
