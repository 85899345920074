export default {
  navLinks: {
    home: "الصفحة الرئيسية",
    university: "الجامعات",
    vission: "الرؤية",
    blogs: "المقالات",
    services: "الخدمات",
    about: "معلومات عنا",
    faq: "الأسئلة الشائعة",
    contact: "تواصل معنا",
    account: "الحساب",
    register: "التسجيل",
    login: "تسجيل الدخول",
    ar: "AR",
    en: "EN",
  },
  hero: {
    title: "مرحبًا بكم في خدمات الدراسة في الخارج مع المسار التعليمي! يعد الشروع في رحلة الدراسة في الخارج تجربة تحولية تفتح أبوابًا لفرص جديدة وتوسع الآفاق. نحن في المسار التعليمي متخصصون في إرشاد الطلاب خلال العملية بأكملها، لضمان انتقال سلس إلى الجامعات المرموقة في جميع أنحاء العالم، بما في ذلك تركيا، قبرص، كندا، الولايات المتحدة الأمريكية، أوروبا وغيرها.",
    subtitle: "في المسار التعليمي، نقدم مجموعة كاملة من الخدمات لضمان أن تكون رحلتك الدراسية في الخارج سلسة وناجحة.",
    name: "الاسم و اللقب",
    mobile: "رقم الهاتف المحمول",
    email: "عنوان البريد الإلكتروني",
    nationality: "الجنسية",
    country: "بلد الإقامة",
    city: "المدينة",
    degree: "درجة الدراسة",
    appoiment: "موعد",
    program: "اسم البرنامج",
    warning: "يرجى ملاحظة أننا لا نقدم منح دراسية مجانية. بإكمال هذا النموذج، فإنك توافق على تخزين واستخدام معلوماتك لأغراض التواصل. نؤكد لك أن بياناتك لن تتم مشاركتها مع أي طرف ثالث.",
    submit: "إرسال",
    contact: "اتصل بنا",
    fill: "املأ النموذج أدناه حتى نتمكن من الاتصال بك",
    type: "نوع الحضور",
    type1: "أونلاين",
    type2: "حضوري",
    study1: "بكالوريوس",
    study2: "ماجستير",
    study3: "دكتوراه",
    study4: "دبلوم",
    availableTime: "لا يوجد مواعيد متاحة اليوم",
    copyRight: "© 2024 حقوق النشر:",
  },
  universities: {
    title: "أفضل الجامعات",
    apply: "قدم الآن",
  },
  vission: {
    title: "الرؤية والرسالة",
    vission: "الرؤية",
    vission_title: "أن نكون المبتكر الرائد في توجيه التعليم والوظائف، حيث يتم تحقيق إمكانات كل طالب بالكامل من خلال مسارات مخصصة ومعززة بالذكاء الاصطناعي لتحقيق النجاح الأكاديمي والمهني.",
    mission: "الرسالة",
    mission_title: "إحداث ثورة في رحلة التعليم العالي وتحقيق الرضا الوظيفي من خلال دمج التكنولوجيا المتطورة مع التوجيه الخبير، لضمان حصول كل طالب على رؤى مخصصة وقابلة للتنفيذ تتماشى مع نقاط قوته وتطلعاته الفريدة.",
    personalization: "التخصيص",
    p_title: "التعرف على اهتمامات وقدرات وتطلعات كل طالب وتلبية احتياجاته، لضمان تقديم توصيات وإرشادات مخصصة.",
    empathy: "التعاطف",
    e_title: "مزج دقة الذكاء الاصطناعي مع التعاطف والبصيرة من قبل مستشارين بشريين ذوي خبرة، لضمان شعور الطلاب بالفهم والتقدير والدعم.",
    excellence: "التميز",
    ex_title: "السعي لتحقيق التميز في جميع جوانب خدماتنا، من توصيات الجامعات إلى استشارات الوظائف والمساعدة في تقديم الطلبات.",
    empowerment: "التمكين",
    em_title: "تزويد الطلاب بالمعرفة والأدوات التي يحتاجونها لاتخاذ قرارات مستنيرة بشأن تعليمهم ووظائفهم، وتعزيز الثقة والاستقلالية.",
    integrity: "النزاهة",
    i_title: "التمسك بأعلى معايير الصدق والشفافية في تفاعلاتنا، لضمان الثقة والمصداقية في خدماتنا.",
    collaboration: "التعاون",
    c_title: "تعزيز بيئة تعاونية حيث تعمل التكنولوجيا والخبرة البشرية معًا لتحقيق أفضل النتائج لطلابنا.",
    commitment: "الالتزام",
    co_title: "تخصيص أنفسنا للتحسين المستمر لخدماتنا ونجاح طلابنا، مع السعي دائمًا لتجاوز التوقعات.",
    footer: "من خلال الالتزام بهذه القيم، يلتزم المسار التعليمي بإرشاد الطلاب خلال رحلاتهم التعليمية بحلول مبتكرة ونهج متعاطف، مما يمهد الطريق لنجاحهم المستقبلي.",
  },
  blogs: {
    title: "المقالات",
    button: "اقرأ المزيد",
  },
  services: {
    title: "خدماتنا الشاملة",
    s1_title: "استشارة مخصصة",
    s1: "إرشادات فردية: نحن نقدم إرشادات مخصصة لمساعدتك في اختيار الجامعة والبرنامج المناسبين الذين يتماشى مع أهدافك الأكاديمية وتفضيلاتك الشخصية. يأخذ مستشارونا الخبراء في الاعتبار نقاط قوتك وتطلعاتك واهتماماتك الفريدة.",
    s2_title: "مساعدة في تقديم الطلبات",
    s2: "دعم شامل: يساعدك فريقنا في عملية تقديم الطلبات بأكملها، لضمان إعداد جميع الوثائق بدقة وتقديمها في الوقت المناسب. نحن نساعدك في صياغة بيانات شخصية مقنعة والاستعداد للمقابلات، مما يعزز فرص قبولك.",
    s3_title: "دعم التأشيرات",
    s3: "مساعدة شاملة: يمكن أن يكون التنقل في متطلبات التأشيرة مرهقًا. نحن نقدم دعمًا شاملاً لمساعدتك على فهم وإكمال عملية تقديم طلب التأشيرة بنجاح، وضمان تلبية جميع المتطلبات اللازمة.",
    s4_title: "ترتيبات السفر والإقامة",
    s4: "تخطيط دقيق: نحن نساعد في تخطيط سفرك وتأمين سكن آمن ومريح، سواء كان داخل الحرم الجامعي أو خارجه. هدفنا هو ضمان انتقال سلس إلى بيئتك الجديدة.",
    s5_title: "توجيه قبل المغادرة",
    s5: "تحضير شامل: نحن نعدك لمغامرتك الجديدة من خلال جلسات توجيهية تغطي الجوانب المهمة للحياة والدراسة في الخارج. يتضمن ذلك رؤى ثقافية وتوقعات أكاديمية ونصائح عملية لمساعدتك على التكيف بسرعة.",
    s6_title: "دعم مستمر",
    s6: "مساعدة مستمرة: لا ينتهي دعمنا بمجرد وصولك إلى وجهتك. نستمر في تقديم المساعدة لمساعدتك على الاندماج بسلاسة والنجاح أكاديميًا، وضمان حصولك على الموارد التي تحتاجها طوال تجربة الدراسة في الخارج.",
    why: "لماذا يجب عليك",
    apply: " التقديم؟",
    titlepart: "معنا؟",
    subtitle: "في المسار التعليمي، نحن ملتزمون بتحويل تطلعاتك التعليمية والمهنية إلى واقع، خاصة إذا كنت تهدف للدراسة في الخارج. إليك السبب في أن اختيارنا سيكون القرار الأفضل لمستقبلك",
    w1_title: "إرشادات مخصصة",
    w1: "توصيات مخصصة: نحن ندرك أن كل طالب فريد من نوعه. توصياتنا الجامعية المخصصة مصممة لتتناسب مع اهتماماتك وقدراتك وتطلعاتك المهنية، لضمان توافق مثالي لنموك الأكاديمي والشخصي، سواء محليًا أو دوليًا.",
    w2_title: "تكنولوجيا متطورة",
    w2: "رؤى تعتمد على الذكاء الاصطناعي: باستخدام أحدث تقنيات الذكاء الاصطناعي، نقدم رؤى دقيقة تعتمد على البيانات لتحسين مواد تقديم الطلبات الخاصة بك، مما يجعلك تبرز في ساحة القبول الدولية التنافسية.",
    w3_title: "مستشارون خبراء",
    w3: "محترفون ذوو خبرة: يجمع فريقنا من المستشارين التعليميين ذوي الخبرة العميقة بين الخبرة والتعاطف، مما يوفر لك إرشادات تتسم بالمعرفة والتفهم. مستشارونا لديهم خبرة واسعة في نظام التعليم الدولي ويمكنهم مساعدتك في التنقل بفعالية.",
    w4_title: "نهج شامل",
    w4: "حلول متكاملة: نقدم نهجًا شاملاً لقبول الجامعات وتخطيط المسار المهني من خلال مزج دقة الذكاء الاصطناعي مع اللمسة الإنسانية. يضمن هذا النهج المزدوج حصولك على دعم شامل للدراسة في الخارج، بدءًا من اختيار الجامعات المناسبة إلى فهم الفروق الثقافية.",
    w5_title: "خدمات شاملة للدراسة في الخارج",
    w5: "دعم شامل: تشمل خدماتنا جميع جوانب الدراسة في الخارج. توصيات الجامعات: نحدد الجامعات حول العالم التي تتوافق بشكل أفضل مع ملفك الشخصي. مساعدة في تقديم الطلبات: يساعدك فريقنا في صياغة بيانات شخصية مقنعة، والاستعداد للمقابلات، وإدارة عملية تقديم الطلبات بأكملها. إرشادات التأشيرة: نقدم مساعدة خطوة بخطوة في تقديم طلبات التأشيرة لضمان انتقال سلس. توجيه قبل المغادرة: نقدم جلسات لمساعدتك على فهم ما تتوقعه وكيفية التحضير لحياة في بلد جديد. مساعدة في السكن: نرشدك في العثور على خيارات السكن المناسبة بالقرب من جامعتك المختارة.",
    w6_title: "التمكين",
   

    w6: "المعرفة والأدوات: نزودك بالمعرفة والأدوات اللازمة لاتخاذ قرارات مستنيرة بشأن تعليمك ومسارك المهني، مما يعزز الثقة والاستقلالية، خاصة عند الدراسة في بلد أجنبي.",
    w7_title: "الالتزام بالتميز",
    w7: "ملتزمون بنجاحك: يعني التزامنا بالتميز أننا نسعى باستمرار لتحسين خدماتنا وتجاوز توقعاتك، وضمان أعلى معايير الجودة والدعم.",

    how: "كيفية",
    howtitle: "جاهز لاتخاذ الخطوة التالية في رحلتك التعليمية؟ اتصل بنا اليوم لتعلم المزيد عن الدراسة في الخارج ولبدء عملية تقديم طلبك.",
    company: "المسار التعليمي",
    title2: "نحن ملتزمون بمساعدتك في تحقيق أحلامك الأكاديمية في الخارج. دعنا نرشدك إلى تجربة تعليمية دولية ناجحة وثرية.",
    applybtn: "قدم الآن",
  },
  about: {
    title: "حول",
    name: "المسار التعليمي",
    text: "مرحبًا بكم في إي-إبتكار، حيث تلتقي التكنولوجيا بالتوجيه لإنشاء مسارات النجاح. نحن شركة تطوير برمجيات متقدمة تستخدم أدوات متطورة لتطوير التطبيقات والذكاء الاصطناعي لتحقيق نجاح العملاء. مهمتنا، التي نحن شغوفون بها، هي إحداث ثورة في قبول الجامعات واستشارات الوظائف، مما يجعل رحلة التعليم العالي سلسة ومخصصة لكل طالب. نحن نؤمن بمستقبل حيث يتم تحقيق إمكانات كل طالب بالكامل.",
    text2: "المسار التعليمي، علامة تجارية لشركة إي-إبتكار، هو دليلك الشخصي لقبول الجامعات واستشارات الوظائف. نحن ندرك أن لكل طالب اهتمامات وقدرات وتطلعات فريدة. يجمع نهجنا المبتكر بين خبرة مستشارينا التعليميين ذوي الخبرة مع تحليل الذكاء الاصطناعي المتقدم. يضمن هذا النهج المزدوج حصولك على توصيات مخصصة تتماشى مع نقاط قوتك وطموحاتك، مما يجعلك تشعر بالفهم والتقدير.",
    text3: "التزامنا هو تمكين الطلاب بالمعرفة والأدوات التي يحتاجونها لاتخاذ قرارات مستنيرة بشأن تعليمهم ومسارهم المهني. من خلال مزج دقة الذكاء الاصطناعي مع التعاطف والبصيرة من قبل مستشارين بشريين، نقدم نهجًا شاملاً لقبول الجامعات وتخطيط المسار المهني.",
  },
  faq: {
    title: "الأسئلة الشائعة",
    subtitle: "الأسئلة المتكررة",
  },
  ready: {
    title: "جاهز ",
    apply: "للتقديم؟",
    subtitle: "املأ النموذج أدناه",
    name: "الاسم و اللقب",
    mobile: "رقم الهاتف المحمول",
    email: "عنوان البريد الإلكتروني",
    nationality: "اختر الجنسية",
    country: "بلد الإقامة",
    degree: "درجة الدراسة",
    program: "اسم البرنامج",
    warning: "يرجى ملاحظة أننا لا نقدم منح دراسية مجانية. بإكمال هذا النموذج، فإنك توافق على تخزين واستخدام معلوماتك لأغراض التواصل. نؤكد لك أن بياناتك لن تتم مشاركتها مع أي طرف ثالث.",
    submit: "إرسال",
  },
  account: {
    register: {
      title: "إنشاء حساب جديد",
      fname: "الاسم الأول",
      lname: "الاسم الأخير",
      email: "عنوان البريد الإلكتروني",
      password: "كلمة المرور",
      submit: "التسجيل",
      login: "لديك حساب بالفعل؟",
    },
    login: {
      title: "مرحبًا بعودتك",
      email: "عنوان البريد الإلكتروني",
      password: "كلمة المرور",
      new: "حساب جديد؟",
      forget: "نسيت كلمة المرور؟",
      submit: "تسجيل الدخول",
    },
  },
  applynow: {
    title: "قدم الآن",
    step1: "معلومات شخصية",
    step2: "معلومات الدراسة",
    step3: "معلومات الوثائق",
    name: "الاسم",
    surname: "اللقب",
    gender: "الجنس",
    male: "ذكر",
    female: "أنثى",
    father: "اسم الأب",
    mother: "اسم الأم",
    email: "عنوان البريد الإلكتروني",
    confirmemail: "تأكيد البريد الإلكتروني",
    phone: "رقم الهاتف",
    confirmphone: "تأكيد رقم الهاتف",
    dual: "لدي جنسية مزدوجة",
    nationality: "الجنسية",
    country: "البلد",
    passport: "رقم جواز السفر",
    snationality: "الجنسية الثانية",
    spassport: "رقم جواز السفر الثاني",
    university: "الجامعة",
    Major: "التخصص",
    lang: "لغة الدراسة",
    student: "صورة الطالب",
    passportpic: "صورة جواز السفر",
    spassportpic: "جواز السفر الثاني",
    certificate: "الشهادة",
    transcript: "السجل الأكاديمي",
    ex1: "وثيقة إضافية 1",
    ex2: "وثيقة إضافية 2",
    ex3: "وثيقة إضافية 3",
    ex4: "وثيقة إضافية 4",
    ex5: "وثيقة إضافية 5",
    submit: "إرسال",
    next: "التالي",
    previous: "السابق",
  },
};