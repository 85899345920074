import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

//Fontawesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fas, fab);

//Custom Locale
import ar from "./locale/ar";
import en from "./locale/en";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";


const vuetify = createVuetify({
  components,
  directives,
  locale: {
    locale: "en",
    messages: { en, ar },
  },
});

//Bootstrap

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

//Custom Style
import "./assets/style/main.css";

//State Mangment
import store from "./store/index";

//Image Viewer
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";

//Text Editor
import PrimeVue from "primevue/config";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import "primeicons/primeicons.css";

//Cutstom Phone Input
import 'maz-ui/styles'

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify, {
    iconfont: "fas",
  })
  .use(Viewer)
  .use(PrimeVue)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("InputText", InputText)
  .component("Button", Button)
  .mount("#app");
